import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    InputWrapper,
    InputElement,
    LabelTextWrapper,
    CheckInputIconWrapper,
    EyePasswordIconWrapper,
    InnerElementsWrapper,
    OpenEyePasswordIconWrapper,
    CurrencyEuro,
    CurrencyGBP,
    CurrencyDollar,
    CurrencyXTS,
    CurrencyBRL,
    InputWarningIconWrapper,
    CurrencyRON,
} from 'src/domains/players/webview/components/form/input/Input.style';
import { InputState } from './InputState';
import { CurrencySymbolType } from 'src_common/common/amount/website-money/currency';
import { assertNever } from 'src_common/common/assertNever';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

const renderIconCurrency = (currency: CurrencySymbolType | undefined): JSX.Element | null => {
    switch (currency) {
        case '€':
            return <CurrencyEuro />;
        case '£':
            return <CurrencyGBP />;
        case '$':
        case 'C$':
        case 'NZ$':
            return <CurrencyDollar />;
        case 'X':
            return <CurrencyXTS />;
        case '₿':
            return <CurrencyXTS />;
        case 'R$':
            return <CurrencyBRL />;
        case 'PLN':
            return <CurrencyXTS />;
        case 'RON':
            return <CurrencyRON>RON</CurrencyRON>;
        case undefined:
            return null;
    }

    return assertNever('', currency);
};

export type InputTypeType = 'text' | 'email' | 'password' | 'number' | 'tel' | 'dropdown' | 'single-option-dropdown';

export interface InputElementType {
    className?: string;
    /** @default false */
    isDisabled?: boolean;
    /** @default false */
    isReadOnly?: boolean;
    label?: string | JSX.Element;
    capitalizeLabel?: boolean;
    /** @default 'text' */
    type?: InputTypeType;
    name?: string;
    placeholder?: string;
    placeholderSize?: string;
    maxLength?: number | undefined;
    inputRef?: React.Ref<HTMLInputElement>;
    state: FormInputState<string, unknown>;
    onClick?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onInput?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    showPassword?: () => void;
    showPasswordFlag?: boolean;
    showSuccessIcon?: boolean;
    inputTextAlign?: 'left' | 'right' | 'center' | 'inherit';
    inputHasBackground?: boolean;
    isShowPassword?: boolean;
    autocomplete?: 'on' | 'off' | 'new-password';
    asideElement?: (() => HTMLElement | null) | undefined;
    currency?: CurrencySymbolType;
    /** @default "light" */
    colorTheme?: 'dark' | 'light';
    errorMarginBottom?: string;
    dataTest?: string;
}

export const Input = observer(
    'Input',
    ({
        className,
        isDisabled = false,
        isReadOnly = false,
        label,
        type = 'text',
        name,
        placeholder,
        placeholderSize,
        maxLength,
        inputRef,
        state,
        onClick,
        onInput,
        onChange,
        onFocus,
        onBlur,
        showPassword,
        showPasswordFlag,
        showSuccessIcon = false,
        inputTextAlign,
        inputHasBackground = true,
        isShowPassword,
        autocomplete = 'on',
        asideElement,
        currency,
        colorTheme = 'dark',
        errorMarginBottom,
        dataTest,
    }: InputElementType) => {
        const eventHandlers = {
            onClick,
            onInput,
            onChange,
            onFocus,
            onBlur,
        };

        const inputFlags = {
            asideElement,
        };

        const [inputState] = React.useState(() => new InputState(state, eventHandlers, inputFlags));

        const statusMessage = inputState.errorMessage;
        const isError = inputState.errorMessage !== null;
        const isSuccess = state.isVisited() === true && inputState.errorMessage === null;

        const renderStatusMessage = (): JSX.Element | null => {
            if (statusMessage !== null) {
                return (
                    <Messages
                        type='error'
                        message={statusMessage}
                        marginBottom={errorMarginBottom}
                    />
                );
            }

            return null;
        };

        const isErrorIconVisible = (): boolean => {
            return isError;
        };

        const renderErrorIcon = (): JSX.Element | null => {
            if (isErrorIconVisible()) {
                return <InputWarningIconWrapper />;
            }

            return null;
        };

        const isSuccessIconVisible = (): boolean => {
            return isSuccess && showSuccessIcon && inputState.value !== '';
        };

        const renderSuccessIcon = (): JSX.Element | null => {
            if (isSuccessIconVisible()) {
                return <CheckInputIconWrapper />;
            }

            return null;
        };

        const renderPasswordIcon = (isShowPassword: boolean | undefined): JSX.Element | null => {
            if (showPasswordFlag === true) {
                return isShowPassword === true ? (
                    <OpenEyePasswordIconWrapper
                        onClick={showPassword}
                        isSuccessIconVisible={isSuccessIconVisible()}
                        isErrorIconVisible={isErrorIconVisible()}
                    />
                ) : (
                    <EyePasswordIconWrapper
                        onClick={showPassword}
                        isSuccessIconVisible={isSuccessIconVisible()}
                        isErrorIconVisible={isErrorIconVisible()}
                    />
                );
            }
            return null;
        };

        const hasLabel = label !== undefined;

        return (
            <InputWrapper
                className={className}
                data-test='input'
            >
                <label>
                    {hasLabel ? (
                        <LabelTextWrapper
                            disabled={isDisabled}
                            isEmpty={inputState.isEmpty}
                            isFocus={inputState.isFocus}
                            readOnly={isReadOnly}
                            colorTheme={colorTheme}
                            data-test={`label-text-${dataTest ?? ''}`}
                        >
                            {label}
                        </LabelTextWrapper>
                    ) : null}
                    <InnerElementsWrapper>
                        {renderIconCurrency(currency)}
                        <InputElement
                            disabled={isDisabled}
                            onClick={inputState.handleClick}
                            onBlur={inputState.handleBlur}
                            onChange={inputState.handleChange}
                            onFocus={inputState.handleFocus}
                            onInput={inputState.handleInput}
                            readOnly={isReadOnly}
                            type={type}
                            value={inputState.value}
                            isError={isError}
                            isSuccess={isSuccess}
                            autoComplete={autocomplete}
                            name={name}
                            placeholder={placeholder}
                            placeholderSize={placeholderSize}
                            maxLength={maxLength}
                            hasLabel={hasLabel}
                            ref={inputRef}
                            textAlign={inputTextAlign}
                            hasBackground={inputHasBackground}
                            currency={currency}
                            colorTheme={colorTheme}
                            data-test={dataTest}
                        />
                        {renderPasswordIcon(isShowPassword)}
                        {renderSuccessIcon()}
                        {renderErrorIcon()}
                    </InnerElementsWrapper>
                    {renderStatusMessage()}
                </label>
            </InputWrapper>
        );
    }
);
