import { Resource } from 'src_common/common/mobx-utils/Resource';
import { apiCommon } from 'src/api/ApiCommon';
import { computed, makeObservable } from 'mobx';
import { TransactionItemType } from 'src/api/config/accounts/accountsWithdrawalsList';

export class WithdrawalsListState {

    private readonly withdrawalsResource: Resource<Array<TransactionItemType>>;

    public constructor() {
        makeObservable(this);
        this.withdrawalsResource = new Resource(async (): Promise<Array<TransactionItemType>> => {
            return await apiCommon.withdrawalsList.run({});
        });
    }

    @computed public get withdrawals(): Array<TransactionItemType> {
        const result = this.withdrawalsResource.get();
        if (result.type === 'ready') {
            return result.value;
        }
        return [];
    }

    public refresh():void {
        this.withdrawalsResource.refresh();
    }

}
