import * as React from 'react';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { AccountHelperWrapper, AccountHelperLink, Separator } from './AccountHelperInfo.style';
import { useAppStateContext } from 'src/appState/AppState';
import { observer } from 'src/utils/mobx-react';
import { TermsPage } from 'src/domains/players/webview/components/SignUp';

interface AccountHelperInfoPropsType {
    version?: 'primary' | 'secondary'; // Please change the name to something more specific.
}

export const AccountHelperInfo = observer(
    'AccountHelperInfo',
    ({ version = 'primary' }: AccountHelperInfoPropsType) => {
        const { appLayoutsState, appPlayersState } = useAppStateContext();
        const {
            configComponents: { config },
        } = appLayoutsState;
        const { cmsDrawerPagesState } = appPlayersState;
        const { hasNeedHelpAccountSection, accountHelperMail } = config;

        if (!hasNeedHelpAccountSection) {
            return null;
        }

        if (config.accountDrawerNew) {
            return (
                <AccountHelperWrapper>
                    <AccountHelperLink
                        to={{ account: 'static', static: 'help' }}
                        dataTest='help-and-faq'
                        version={version}
                    >
                        <I18n langKey='account.help-and-faq' defaultText="Help and FAQ's" />
                    </AccountHelperLink>

                    <Separator version={version}>|</Separator>

                    <AccountHelperLink to={`mailto:${accountHelperMail}`} dataTest='support-mail' version={version}>
                        <I18n langKey='account.contact-support' defaultText='Contact Support' />
                    </AccountHelperLink>
                </AccountHelperWrapper>
            );
        }

        return (
            <>
                {cmsDrawerPagesState.displayPage === true &&
                cmsDrawerPagesState.pageType === 'help' &&
                cmsDrawerPagesState.title !== null &&
                cmsDrawerPagesState.content !== null ? (
                    <TermsPage
                        title={cmsDrawerPagesState.title}
                        content={cmsDrawerPagesState.content}
                        closePage={cmsDrawerPagesState.closePage}
                    />
                ) : null}
                <AccountHelperWrapper>
                    <AccountHelperLink
                        onClick={cmsDrawerPagesState.showHelpPage}
                        dataTest='help-and-faq'
                        version={version}
                    >
                        <I18n langKey='account.help-and-faq' defaultText="Help and FAQ's" />
                    </AccountHelperLink>

                    <Separator version={version}>|</Separator>

                    <AccountHelperLink to={`mailto:${accountHelperMail}`} dataTest='support-mail' version={version}>
                        <I18n langKey='account.contact-support' defaultText='Contact Support' />
                    </AccountHelperLink>
                </AccountHelperWrapper>
            </>
        );
    }
);
