import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { HaveAccountWrapper, HaveAccountText, HaveAccountRedirect } from './AlreadyHaveAccount.styles';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';

export const AlreadyHaveAccount = observer('AlreadyHaveAccount', () => {
    const { appLayoutsState } = useAppStateContext();
    const { starRouter } = appLayoutsState;

    return (
        <HaveAccountWrapper>
            <HaveAccountText data-test='have-account-text'>
                <I18n langKey='signup-journey.footer.have-account' defaultText='Already have an account?' />
            </HaveAccountText>

            <HaveAccountRedirect onClick={starRouter.redirectToLogin} dataTest='have-account-redirect'>
                <I18n langKey='signup-journey.footer.login' defaultText='Login' />
            </HaveAccountRedirect>
        </HaveAccountWrapper>
    );
});
